import { HttpClient } from '@leanpay/http-client'
import {
    CardPaymentPrepareRequest,
    CardPaymentPrepareResponse,
    CardPaymentResolvedRequest,
    CardPaymentStatusData,
    CardPaymentStatusResponse,
} from '@leanpay/models'

import { preQualifiedErrorHandler } from '../errorHandler'
import { clientInterceptor } from '../interceptors'

class CreditCardService {
    _httpClient = new HttpClient({
        config: { baseURL: undefined, responseType: 'json' },
        interceptorConfig: {
            onRequestFulfilled: clientInterceptor as any,
            onRejected: preQualifiedErrorHandler,
        },
    })

    private _prepareUrl = 'api/card-payment/prepare'
    private _resolveUrl = 'api/card-payment/resolve'
    private _statusUrl = 'api/card-payment/status'

    async prepareCreditCard(request: CardPaymentPrepareRequest) {
        const { data } = await this._httpClient.post<CardPaymentPrepareResponse>(
            this._prepareUrl,
            request,
        )

        return data
    }

    async resolveCreditCard(request: CardPaymentResolvedRequest) {
        const { data } = await this._httpClient.post<any>(this._resolveUrl, request)

        return data
    }

    async getCreditCardStatus(cardPaymentId: string) {
        const statusUrl = `${this._statusUrl}?cardPaymentId=${cardPaymentId}`
        const { data } = await this._httpClient.get<CardPaymentStatusResponse>(statusUrl)
        return new CardPaymentStatusData(data)
    }
}

export const creditCardService = new CreditCardService()
