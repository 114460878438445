import {
    EmploymentContractType,
    EmploymentType,
    ExecutionAmount,
    ExecutionAmountDTO,
    Gender,
    IDDocumentType,
    IndustryOfEmployment,
    LatePaymentTime,
    SessionInfo,
    TwoFAStatus,
    UserEmploymentType,
} from '@leanpay/models'
import {
    Language,
    Option,
    getCountryPhoneCodes,
    getIbanValidationOptions,
    renderDecimalNumber,
} from '@leanpay/utils'
import { TFunction } from 'i18next'

import i18n from '../i18n'

import {
    CheckoutRoute,
    currentMarket,
    isMarketHun,
    isMarketRom,
    isMarketSlo,
    isProdEnvironment,
} from './constants'

export const handleCheckoutProgress = (route: string, setProgress: (progress: number) => void) => {
    if (isMarketRom) return handleCheckoutProgressROM(route, setProgress)
    if (isMarketHun) return handleCheckoutProgressHUN(route, setProgress)
    if (isMarketSlo) return handleCheckoutProgressSLO(route, setProgress)
}

const handleCheckoutProgressROM = (route: string, setProgress: (progress: number) => void) => {
    switch (route.replace('/', '')) {
        case CheckoutRoute.ChoseInstallmentPlan:
            setProgress(10)
            break
        case CheckoutRoute.RequestOtp:
            setProgress(20)
            break
        case CheckoutRoute.Login:
            setProgress(30)
            break
        case CheckoutRoute.PersonalDetails:
            setProgress(40)
            break
        case CheckoutRoute.AddressDetails:
            setProgress(50)
            break
        case CheckoutRoute.TwoFaPin:
            setProgress(55)
            break
        case CheckoutRoute.VerificationInfo:
            setProgress(60)
            break
        case CheckoutRoute.KYCVerification:
            setProgress(65)
            break
        case CheckoutRoute.ValidateOffer:
            setProgress(75)
            break
        case CheckoutRoute.OfferConfirmation:
            setProgress(85)
            break
        case CheckoutRoute.SigContract:
            setProgress(90)
            break
        case CheckoutRoute.PepQuestionnaire:
            setProgress(95)
            break
        case CheckoutRoute.ForgotTwoFaPin:
            setProgress(100)
            break
        case CheckoutRoute.FinancialDetails:
            setProgress(100)
            break
        case CheckoutRoute.CheckoutSuccess:
            setProgress(100)
            break
        default:
            setProgress(100)
            break
    }
}

const handleCheckoutProgressHUN = (route: string, setProgress: (progress: number) => void) => {
    switch (route.replace('/', '')) {
        case CheckoutRoute.ChoseInstallmentPlan:
            setProgress(5)
            break
        case CheckoutRoute.RequestOtp:
            setProgress(10)
            break
        case CheckoutRoute.Login:
            setProgress(15)
            break
        case CheckoutRoute.PersonalDetails:
            setProgress(20)
            break
        case CheckoutRoute.AddressDetails:
            setProgress(25)
            break
        case CheckoutRoute.FinancialDetails:
            setProgress(30)
            break
        case CheckoutRoute.PreviewFinancialDetails:
            setProgress(35)
            break
        case CheckoutRoute.AisRequest:
            setProgress(40)
            break
        case CheckoutRoute.AisStatus:
            setProgress(45)
            break
        case CheckoutRoute.TwoFaPin:
            setProgress(50)
            break
        case CheckoutRoute.ValidateOffer:
            setProgress(55)
            break
        case CheckoutRoute.OfferConfirmation:
            setProgress(60)
            break
        case CheckoutRoute.SigContract:
            setProgress(65)
            break
        case CheckoutRoute.PepQuestionnaire:
            setProgress(65)
            break
        case CheckoutRoute.ForgotTwoFaPin:
            setProgress(65)
            break
        case CheckoutRoute.VerificationInfo:
            setProgress(85)
            break
        case CheckoutRoute.KYCVerification:
            setProgress(90)
            break
        case CheckoutRoute.KYCVerificationSuccess:
            setProgress(95)
            break
        case CheckoutRoute.CheckoutSuccess:
            setProgress(100)
            break
        default:
            setProgress(100)
            break
    }
}
const handleCheckoutProgressSLO = (route: string, setProgress: (progress: number) => void) => {
    switch (route.replace('/', '')) {
        case CheckoutRoute.ChoseInstallmentPlan:
            setProgress(7)
            break
        case CheckoutRoute.RequestOtp:
            setProgress(14)
            break
        case CheckoutRoute.Login:
            setProgress(21)
            break
        case CheckoutRoute.PersonalDetails:
            setProgress(28)
            break
        case CheckoutRoute.TwoFaPin:
            setProgress(35)
            break
        case CheckoutRoute.FinancialDetails:
            setProgress(42)
            break

        case CheckoutRoute.PreviewFinancialDetails:
            setProgress(49)
            break

        case CheckoutRoute.ValidateOffer:
            setProgress(56)
            break
        case CheckoutRoute.OfferConfirmation:
            setProgress(63)
            break
        case CheckoutRoute.SigContract:
            setProgress(70)
            break
        case CheckoutRoute.PepQuestionnaire:
            setProgress(70)
            break
        case CheckoutRoute.ForgotTwoFaPin:
            setProgress(70)
            break
        case CheckoutRoute.Payment:
            setProgress(77)
            break
        case CheckoutRoute.PaymentStatus:
            setProgress(84)
            break
        case CheckoutRoute.CheckoutSuccess:
            setProgress(100)
            break
        default:
            setProgress(100)
            break
    }
}

export const shouldSetTwoFaPin = (sessionInfo?: SessionInfo | null) => {
    if (!sessionInfo) return false

    return sessionInfo?.twoFAStatus === TwoFAStatus.NOT_SET
}

export const getYesNoSelectOption = (translate: TFunction): Option[] => [
    {
        label: translate('financialDetails.labels.yes'),
        value: true,
    },
    {
        label: translate('financialDetails.labels.no'),
        value: false,
    },
]

export const getExecutionAmountOptionsSLO = (translate: TFunction, currency?: string): Option[] => [
    {
        label: translate('enum.executionAmount.LOWER_THAN_100', {
            executionAmount100Start: '0',
            currency: currency ?? '€',
            executionAmount100End: '100',
        }),
        value: ExecutionAmountDTO.LOWER_THAN_100,
    },
    {
        label: translate('enum.executionAmount.BETWEEN_101_200', {
            executionAmount101_200Start: '101',
            executionAmount101_200End: '200',
            currency: currency ?? '€',
        }),
        value: ExecutionAmountDTO.BETWEEN_101_200,
    },
    {
        label: translate('enum.executionAmount.GREATER_THAN_200', {
            executionAmount200Start: '200',
            currency: currency ?? '€',
        }),
        value: ExecutionAmountDTO.GREATER_THAN_200,
    },
]

export const getExecutionAmountOptionsCRO = (translate: TFunction, currency?: string): Option[] => [
    {
        label: translate('enum.executionAmount.LOWER_THAN_100', {
            executionAmount100Start: '0',
            currency: currency ?? '€',
            executionAmount100End: '750',
        }),
        value: ExecutionAmountDTO.LOWER_THAN_100,
    },
    {
        label: translate('enum.executionAmount.BETWEEN_101_200', {
            executionAmount101_200Start: '750',
            executionAmount101_200End: '1500',
            currency: currency ?? '€',
        }),
        value: ExecutionAmountDTO.BETWEEN_101_200,
    },
    {
        label: translate('enum.executionAmount.GREATER_THAN_200', {
            executionAmount200Start: '1500',
            currency: currency ?? '€',
        }),
        value: ExecutionAmountDTO.GREATER_THAN_200,
    },
]

export const getFinancialDetailsOptions = (
    translate: TFunction,
    financialDetailsOptionsData?: string[],
) => {
    return financialDetailsOptionsData?.map((option) => ({
        //@ts-ignore
        label: translate(`enum.numberOfConsecutivePaymentMonths.${option}`) as string,
        value: option,
    }))
}
export const getNrLoansInLastYear = (
    translate: TFunction,
    financialDetailsOptionsData?: string[],
) => {
    return financialDetailsOptionsData?.map((option) => ({
        //@ts-ignore
        label: translate(`enum.nrLoansInLastYear.${option}`) as string,
        value: option,
    }))
}
export const getNrLoansDelay = (translate: TFunction, financialDetailsOptionsData?: string[]) => {
    return financialDetailsOptionsData?.map((option) => ({
        //@ts-ignore
        label: translate(`enum.nrLoansDelaysInLastYear.${option}`) as string,
        value: option,
    }))
}
export const getEmploymentTypesOptions = (
    translate: TFunction,
    employmentTypeData?: UserEmploymentType[],
): Option[] => {
    if (!employmentTypeData) return []
    return employmentTypeData?.map((option) => ({
        //@ts-ignore
        label: translate(`enum.employmentType.${option.name}`) as string,
        value: option.name,
    }))
}

export const getEmploymentContractType = (
    translate: TFunction,
    selectedEmploymentType: EmploymentType | null,
    employmentTypeData?: UserEmploymentType[],
) => {
    if (!employmentTypeData || !selectedEmploymentType) return []

    //Selected employmentType type
    const filteredEmploymentType = employmentTypeData
        .filter(
            (type) =>
                !type.contractTypes.includes(EmploymentContractType.DO_NOT_WANT_TO_ASK) &&
                type.contractTypes.length > 1,
        )
        .find((employmentType) => employmentType.name === selectedEmploymentType)

    return (
        filteredEmploymentType?.contractTypes.map((type) => ({
            //@ts-ignore
            label: translate(`enum.employmentContractType.${type}`) as string,
            value: type,
        })) ?? []
    )
}

const numberOfDependants: number[] = [0, 1, 2, 3, 4, 5, 6]
const numberOfChildren: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const countryPhoneCodes = getCountryPhoneCodes(currentMarket, isProdEnvironment)

export const numberOfDependantsOptions = numberOfDependants.map((option) => ({
    label: String(option),
    value: option,
}))
export const numberOfChildrenOptions = numberOfChildren.map((option) => ({
    label: String(option),
    value: option,
}))

export const getGenderOptions = (translate: TFunction) => {
    const options = [
        {
            value: Gender.Male,
            label: translate('enum.gender.MALE'),
        },
        {
            value: Gender.Female,
            label: translate('enum.gender.FEMALE'),
        },
    ]
    return options
}

export const getLatePayingLoanOptions = (translate: TFunction) => {
    return Object.keys(LatePaymentTime).map((paymentTime) => {
        return {
            value: paymentTime,
            //@ts-ignore
            label: translate(`enum.latePaymentTime.${paymentTime}`) as string,
        }
    })
}

export const formatBBAN = (input?: string): string => {
    if (!input) return ''
    return input
        .split('')
        .map((char, i) => ((i + 1) % 8 === 0 && i !== input.length - 1 ? `${char} - ` : char))
        .join('')
}

//TODO - use existing getter in session info
export const isExistingMembership = (sessionInfo: SessionInfo | null) => {
    if (!sessionInfo) return false
    return (
        sessionInfo.membershipStatus !== 'NOT_SUBMITTED' &&
        sessionInfo.membershipStatus !== 'RESTARTED'
    )
}

export const getMonthLabel = (numberOfMonths?: number) => {
    if (!numberOfMonths) return 'months'
    if (isMarketSlo) {
        if (numberOfMonths === 3) {
            return 'month'
        }

        if (numberOfMonths !== 3) {
            return 'months'
        }
    }

    return 'months'
}

export const addPaydooScript = (
    providerUrl: string,
    providerScriptId: string,
    providerOptionsScriptId: string,
    translate: TFunction,
    appLng: string,
) => {
    // Append options script to body.
    const providerOptionsScript = document.createElement('script')
    providerOptionsScript.type = 'text/javascript'
    providerOptionsScript.setAttribute('id', providerOptionsScriptId)
    providerOptionsScript.innerHTML = `var wpwlOptions = {
        style: "logos",
        locale: "${String(appLng)}",
        brandDetection: true,
        brandDetectionType: "binlist",
        brandDetectionPriority: ["VISA", "MASTER"],
        imageStyle: "svg",
        showLabels: false,
        iframeStyles: {
            'card-number-placeholder': {
                'color': '#c8ced8',
                'font-size': '16px'
            },
            'cvv-placeholder': {
                'color': '#c8ced8',
                'font-size': '16px'
            }
        },
        spinner: {
            color: "#FF786E"
        },
        onReady: function () {
            const cvvGroup = document.querySelector(".wpwl-group-cvv");
            const cardHolderGroup = document.querySelector(".wpwl-group-cardHolder");
            cvvGroup.insertAdjacentElement('afterend', cardHolderGroup);

            const payButton = document.querySelector('.wpwl-form-card .wpwl-button-pay');
            payButton.addEventListener('click', validateHolder);
            payButton.innerHTML = '${translate('payment.authorizationAndBuy')}';
        },
        onBeforeSubmitCard: function (e) {
            return validateHolder(e);
        }
    };

    function validateHolder(e) {
        const holderControl = document.querySelector('.wpwl-control-cardHolder');
        const holder = holderControl.value;

        if (holder.trim().length < 2) {
            holderControl.classList.add('wpwl-has-error');
            const errorMessage = document.createElement('div');
            errorMessage.className = 'wpwl-hint wpwl-hint-cardHolderError';
            errorMessage.innerHTML = '${translate('payment.invalidCardHolder')}';
            holderControl.insertAdjacentElement('afterend', errorMessage);
            return false;
        }
        return true;
    }
    `

    const providerScript = document.createElement('script')
    providerScript.type = 'text/javascript'
    providerScript.setAttribute('id', providerScriptId)
    providerScript.src = providerUrl
    document.body.appendChild(providerScript)
    document.body.appendChild(providerOptionsScript)
}

export const removePaydooScript = (providerScriptId: string, providerOptionsScriptId: string) => {
    // Remove main provider script from DOM if exists.
    const providerScript: HTMLElement | null = document.getElementById(providerScriptId)

    if (providerScript) {
        providerScript.remove()
    }

    // Remove options provider script from DOM if exists.
    const providerOptionsScrip: HTMLElement | null =
        document.getElementById(providerOptionsScriptId)

    if (providerOptionsScrip) {
        providerOptionsScrip.remove()
    }
}

export const shouldShowProgressBar = () => {
    const progressBarExcludedPaths = [
        '/',
        '/privacy-policy',
        '/terms-of-use',
        '/vendor-mock',
        '/complaint-policy',
        '/representative-example',
        '/financial-data-agreement',
        '/credit-bureau-agreement',
        '/cookie-policy',
        '/digital-verification-policy',
    ]

    return !progressBarExcludedPaths.includes(window.location.pathname)
}

export const isStandalonePublicRoute = [
    '/kyc-verification',
    '/payment-status',
    '/privacy-policy',
    '/complaint-policy',
    '/terms-of-use',
    '/representative-example',
    '/hardware-and-software-compatibility',
    '/financial-data-agreement',
    '/representative-example',
    '/cookie-policy',
    '/digital-verification-policy',
].includes(window.location.pathname)

export const ibanValidationOptions = getIbanValidationOptions(currentMarket)

export const getIdentityCardTypesHUN = (translate: TFunction) => [
    { label: translate('enum.idDocumentType.PASSPORT'), value: IDDocumentType.PASSPORT },
    {
        label: translate('enum.idDocumentType.PERSONAL_ID'),
        value: IDDocumentType.PERSONAL_ID,
    },
    {
        label: translate('enum.idDocumentType.DRIVING_LICENSE'),
        value: IDDocumentType.DRIVING_LICENSE,
    },
]

export const getIdentityCardValidation = (idDocumentType: string) => {
    switch (idDocumentType) {
        case IDDocumentType.PASSPORT:
            return {
                regex: new RegExp(/^[A-Z]{2}\d{7}$/i),
                maxLength: 9,
            }
        case IDDocumentType.PERSONAL_ID:
            return {
                regex: new RegExp(/^\d{6}[A-Z]{2}$/i),
                maxLength: 8,
            }
        case IDDocumentType.DRIVING_LICENSE:
            return {
                regex: new RegExp(/^[A-Z]{2}\d{6}$/i),
                maxLength: 8,
            }
        default:
            return {
                regex: new RegExp('^.*$'),
                length: undefined,
            }
    }
}

export const getExecutionAmountOptions = (executionAmounts?: ExecutionAmount[]) =>
    executionAmounts?.map((e) => ({
        value: e.id,
        label: e.name,
    }))

export const getIndustryOfEmploymentOptions = (industriesOfEmployment?: IndustryOfEmployment[]) =>
    industriesOfEmployment?.map((i) => ({
        value: i.id,
        label: i18n.language === Language.ENG ? i.nameEn : i.name,
    }))

export const currentLanguage = i18n.language

export const displayDecimalNumber = (value?: string | number) =>
    renderDecimalNumber(value, currentMarket)

export enum KYC_DOCUMENT_TYPES {
    IDENTITY_CARD_FRONT_SIDE = 'IDENTITY_CARD_FRONT_SIDE',
    IDENTITY_CARD_BACK_SIDE = 'IDENTITY_CARD_BACK_SIDE',
    DRIVING_LICENCE_FRONT_SIDE = 'DRIVING_LICENCE_FRONT_SIDE',
    DRIVING_LICENCE_BACK_SIDE = 'DRIVING_LICENCE_BACK_SIDE',
    PASSPORT = 'PASSPORT',
    SELFIE = 'SELFIE',
    ADDRESS_CARD = 'ADDRESS_CARD',
}
export function mapOnfidoSdkResponseToDocumentIds(sdkResponse: any) {
    const temp: { [key: string]: string } = {}

    if (sdkResponse.face) {
        temp[KYC_DOCUMENT_TYPES.SELFIE] = sdkResponse.face.id
    }

    if (sdkResponse.document_front) {
        switch (sdkResponse.document_front.type) {
            case 'passport':
                temp[KYC_DOCUMENT_TYPES.PASSPORT] = sdkResponse.document_front.id
                break
            case 'driving_licence':
                temp[KYC_DOCUMENT_TYPES.DRIVING_LICENCE_FRONT_SIDE] = sdkResponse.document_front.id
                break
            case 'national_identity_card':
                temp[KYC_DOCUMENT_TYPES.IDENTITY_CARD_FRONT_SIDE] = sdkResponse.document_front.id
                break
        }
    }

    if (sdkResponse.document_back) {
        switch (sdkResponse.document_back.type) {
            case 'driving_licence':
                temp[KYC_DOCUMENT_TYPES.DRIVING_LICENCE_BACK_SIDE] = sdkResponse.document_back.id
                break
            case 'national_identity_card':
                temp[KYC_DOCUMENT_TYPES.IDENTITY_CARD_BACK_SIDE] = sdkResponse.document_back.id
                break
        }
    }

    // Now we cast temp to the desired type
    const documentIds = temp as { [key in keyof typeof KYC_DOCUMENT_TYPES]?: string }

    return Object.keys(documentIds).length > 0 ? documentIds : undefined
}
