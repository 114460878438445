import {
    CardPaymentStatusData,
    CheckoutData,
    ContractData,
    FinancialDetailsV2,
    MaturityType,
    OfferValidation,
    PaymentData,
    PaymentPlan,
    PersonalDetails,
} from '@leanpay/models'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetSessionInfo, useGetVendorTokenCheckout } from 'library/hooks/query-hooks'
import { isStandalonePublicRoute } from 'library/utils'

import AppContext from './app-context'

const AppStore = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate()
    const { data: sessionInfoData } = useGetSessionInfo()
    const { mutateAsync: mutateGetVendorTokenCheckout } = useGetVendorTokenCheckout()
    const params = new URLSearchParams(window.location.search)
    const location = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [progress, setProgress] = useState<number>(0)
    const [financialDetailsDataV2, setFinancialDetailsDataV2] = useState<FinancialDetailsV2 | null>(
        null,
    )
    const [offerValidationData, setOfferValidationData] = useState<OfferValidation | null>(null)
    const [personalDetailsData, setPersonalDetailsData] = useState<PersonalDetails | null>(null)
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<PaymentPlan | null>(null)
    const [paymentData, setPaymentData] = useState<PaymentData | null>(null)
    const [cardPaymentStatusData, setCardPaymentStatusData] =
        useState<CardPaymentStatusData | null>(null)
    const [checkoutData, setCheckoutData] = useState<CheckoutData | null>(null)
    const [contractData, setContractData] = useState<ContractData[] | null>(null)
    const [vendorToken, setVendorToken] = useState<string>('')
    const [maturityType, setMaturityType] = useState<MaturityType>(MaturityType.PRICE)

    const contextValue = {
        vendorToken,
        isAuthenticated,
        progress,
        personalDetailsData,
        financialDetailsDataV2,
        offerValidationData,
        selectedPaymentPlan,
        paymentData,
        cardPaymentStatusData,
        checkoutData,
        contractData,
        maturityType,
        setVendorToken,
        setIsAuthenticated,
        setProgress,
        setPersonalDetailsData,
        setFinancialDetailsDataV2,
        setOfferValidationData,
        setSelectedPaymentPlan,
        setPaymentData,
        setCardPaymentStatusData,
        setCheckoutData,
        setContractData,
        setMaturityType,
    }

    const handleGetVendorToken = async () => {
        const token = params.get('token')

        if (token) {
            try {
                const vendorInitApiData = await mutateGetVendorTokenCheckout(token)
                setVendorToken(vendorInitApiData.token)
                setCheckoutData(
                    new CheckoutData({
                        token: vendorInitApiData.token,
                        isInStore: vendorInitApiData.inStoreFlag,
                        vendorRequestType: vendorInitApiData.vendorRequestType,
                        customerLanguage: vendorInitApiData.customerLanguage,
                        vendorName: vendorInitApiData.vendorName,
                    }),
                )
                navigate('/choose-installment', {
                    replace: true,
                })
            } catch (error) {
                navigate('/checkout-error', { state: { error: error }, replace: true })
                return
            }
        } else {
            navigate('/checkout-error', { state: { error: 'DATA_NOT_VALID' }, replace: true })
        }
    }

    useEffect(() => {
        if (location.pathname === '/' && !isStandalonePublicRoute) {
            handleGetVendorToken()
        }
    }, [location.pathname])

    useEffect(() => {
        if (sessionInfoData) {
            setIsAuthenticated(true)
        }
    }, [sessionInfoData])

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppStore
