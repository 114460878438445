import { HttpClient } from '@leanpay/http-client'
import { storage } from '@leanpay/local-storage'
import {
    AcceptConsentRequest,
    AccountSingleStepPinRequest,
    AddressData,
    AddressDetailsRequest,
    AddressDetailsResponse,
    ApplicationScoringRequest,
    ApplicationScoringResponse,
    AuthResponse,
    AuthorizationCodeResponse,
    AuthorizationCodeStatusData,
    AuthorizationStatusResponse,
    CancelTransactionRequest,
    ChangePinRequest,
    CheckoutStepRecordRequest,
    CompaniesListDTO,
    ContractData,
    ContractDataComplexDTO,
    ContractSignOrBuyRequest,
    Country,
    CountryCodebookDTO,
    CreatePinRequest,
    Currency,
    CurrencyInfo,
    EmploymentInfoRequest,
    EmploymentMetaTypeDTO,
    ExecutionAmount,
    ExecutionAmountMetaDTO,
    FinancialAccountNumberV2Request,
    FinancialDataResponse,
    FinancialDataStatus,
    FinancialDetailsCheckRequest,
    FinancialDetailsOptionsTypes,
    FinancialDetailsRequestV2,
    FinancialDetailsResponse,
    FinancialDetailsV2,
    FinancialDetailsValidationDataDTO,
    FinancialProductV2Request,
    GetSelfCheckoutTokenRequest,
    IndustryOfEmployment,
    JWTLoginRequest,
    LoanAmountLimitsRequest,
    MaritalStatus,
    MaritalStatusRequest,
    MaritalStatusResponse,
    Nationality,
    NationalityCodebookDTO,
    OfferValidation,
    Page,
    PaymentData,
    PaymentDataDTO,
    PaymentDataRequest,
    PersonMarketingConsentChangeRequest,
    PersonalDetailsCheckRequest,
    PersonalDetailsDTO,
    PersonalDetailsV2,
    PersonalDetailsV2Request,
    PostZip,
    PostZipCodebookDTO,
    PreQualifiedInfoDTO,
    PreQualifiedInfoRequest,
    ProfileAddressChangeRequest,
    ProfileIbanChangeRequest,
    Questionnaire,
    QuestionnaireDTO,
    QuestionnaireListDTO,
    ReCreatePinRequest,
    RecordStepParams,
    RequestFinancialDataRequest,
    ScoringStatus,
    SecurityConfirmationRequest,
    SessionInfo,
    SessionInfoDTO,
    Settlement,
    SettlementCodebookDTO,
    SignOrBuyResponse,
    StateMachineDto,
    Street,
    StreetCodebookDTO,
    TransactionRequest,
    UserEmploymentType,
    ValidateOfferInitialResponse,
    ValidateVendorResponse,
    ValidationDataDTO,
    ValidationDataResultRequest,
    ValidationDataV2DTO,
    Vendor,
    VendorApiInitResponse,
    VendorApiPreQualifiedRequest,
    VendorProductValidationRequest,
    VendorRequestInvalidateRequest,
} from '@leanpay/models'
import { STORAGE_KEY } from '@leanpay/utils'

import { preQualifiedErrorHandler } from '../errorHandler'
import { clientInterceptor } from '../interceptors'

class CheckoutService {
    _httpClient = new HttpClient({
        config: {
            baseURL: undefined,
            responseType: 'json',
        },
        interceptorConfig: {
            onRequestFulfilled: clientInterceptor as any,
            onRejected: preQualifiedErrorHandler,
        },
    })

    private _financialProductInitUrl = 'api/vendor/financial-product-init'
    private _financialTypes = 'api/checkout/financial-types'
    private _employmentTypesUrl = 'api/checkout/employment-types'
    private _maritalStatusesUrl = 'api/checkout/marital-statuses'
    private _maritalStatusUrl = 'api/checkout/marital-status'

    private _submitEmploymentInfo = '/api/checkout/employment-info'
    private _checkFinancialDetailsUrl = 'api/checkout/check-financial-details'

    private _initScoringUrl = 'api/checkout/v1/application-scoring/init'
    private _applicationScoringUrl = 'api/checkout/v1/application-scoring'
    private _validationResultUrl = 'api/checkout/v1/validation-result'
    private _amlWatchListUrl = '/api/checkout/v2/watchlist'
    private _stateMachineUrl = '/api/checkout/state-machine'

    private _requestPinUrl = 'api/checkout/request-pin'
    private _resendOtpUrl = 'api/account/single-step/resend-pin'
    private _resendOtpOverVoiceUrl = 'api/account/single-step/resend-pin-over-voice'
    private _authenticateUrl = 'api/authenticate'

    private _recordStepUrl = 'api/checkout/record-step'
    private _getVendorTokenPQUrlPQ = 'api/vendor/pre-qualified-init'

    private _checkPersonalDetailsUrl = 'api/checkout/check-personal-details'
    private _submitPersonalDetailsV2URL = 'api/checkout/v2/submit-personal-details'
    private _submitAddressDetailsV2 = 'api/checkout/v2/submit-address-details'
    private _loadPersonalDetailsUrl = 'api/checkout/personal-details'
    private _loadAddressDetails = 'api/checkout/v2/personal-address-details'
    private _loadFinancialDetails = 'api/checkout/v2/personal-financial-details'

    private _countryCodebookUrl = 'api/codebook/countries'
    private _industryCodebookUrl = 'api/codebook/industries'
    private _nationalityCodebookUrl = 'api/codebook/nationalities'
    private _settlementsUrl = 'api/codebook/settlements'
    private _postZipBySettlementUrl = '/api/codebook/post-zips/settlement'
    private _streetsByPostZipUrl = '/api/codebook/streets/post-zip/'
    private _postZipsUrl = 'api/codebook/post-zips'
    private _settlementsByStreetUrl = '/api/codebook/settlements/street/'
    private _settlementsByPostZipUrl = '/api/codebook/settlements/post-zip'
    private _streetsBySettlementCodebookId = '/api/codebook/streets'
    private _companyListUrl = 'api/checkout/v2/company-list'

    private _createPinUrl = 'api/authenticate/pin/create'
    private _recreatePinUrl = 'api/authenticate/pin/recreate'
    private _changePinUrl = 'api/authenticate/pin/change'

    private _sessionCheckUrl = 'api/session-info'
    private _preQualifiedInfoUrl = 'api/checkout/pre-qualified/info'
    private _currencyUrl = 'api/currency'

    private _ibanUpdateUrl = 'api/profile/iban'
    private _changeMarketingConsentUrl = 'api/profile/marketing-consent'
    private _changeMarketingConsentLoginActionUrl = 'api/profile/marketing-consent/login-action'
    private _addressUpdateUrl = 'api/profile/address'

    private _paymentDataUrl = 'api/checkout/payment-data'
    private _getVendorTokenUrlCheckout = 'api/vendor/checkout-init'

    private _requestFinancialDataUrl = 'api/checkout/financial-data'

    private _validateOfferUrlV2 = 'api/checkout/v2/validate-offer'
    private _contractDataUrl = 'api/checkout/contract-complex'
    private _pepQuestionnaireUrl = 'api/checkout/pep-questionnaire'
    private _pepQuestionnaireUrlROM = 'api/checkout/v1/pep-questionnaire'
    private _pepStatusUrl = 'api/checkout/pep-status'
    private _signOrBuyUrl = 'api/checkout/transaction/sign-or-buy'
    private _invalidateVendorRequestUrl = 'api/checkout/invalidate-vendor-request'
    private _validateVendorRequestUrl = 'api/checkout/validate-vendor-request'
    private _cancelTransactionUrl = 'api/checkout/transaction/cancel'

    private _checkBankAccountNumber = 'api/checkout/v2/bank-account-number'
    private _submitFinancialDetails = 'api/checkout/v2/financial-details'
    private _executionAmountsUrl = 'api/checkout/execution-amounts'
    private _loanAmountLimitsUrl = 'api/checkout/loan-amount-limits'

    private _getSelfCheckoutTokenUrl = 'api/self-checkout/offers'
    private _authorizationCode = 'api/self-checkout/authorization-code'
    private _authorizationCodeStatus = '/api/self-checkout/authorization-status'
    private _confirmAuthorizationCodePrompt = '/api/self-checkout/confirm-offer'

    private _validateVendorProductUrl = 'api/checkout/validate-vendor-product'

    private _acceptConsentUrl = 'api/consents'

    async acceptConsent(request: AcceptConsentRequest) {
        const { data } = await this._httpClient.post(this._acceptConsentUrl, request)
        return data
    }

    //SELF CHECKOUT
    async getSelfCheckoutToken(request: GetSelfCheckoutTokenRequest) {
        const { data } = await this._httpClient.post<{ token: string }>(
            this._getSelfCheckoutTokenUrl,
            request,
        )

        return data
    }

    async getAuthorizationCode(token: string): Promise<AuthorizationCodeResponse> {
        const url = `${this._authorizationCode}?token=${token}`
        const { data } = await this._httpClient.get<AuthorizationCodeResponse>(url)
        return data
    }

    async getAuthorizationCodeStatus(token: string): Promise<AuthorizationCodeStatusData> {
        const url = `${this._authorizationCodeStatus}?token=${token}`
        const { data } = await this._httpClient.get<AuthorizationStatusResponse>(url)
        return new AuthorizationCodeStatusData(data)
    }
    async confirmAuthorizationCodePrompt(payload: SecurityConfirmationRequest) {
        const { data } = await this._httpClient.post<any>(
            this._confirmAuthorizationCodePrompt,
            payload,
        )

        return data
    }

    //CHECKOUT
    async getPepQuestionnaireROM() {
        const { data } = await this._httpClient.get<QuestionnaireListDTO>(
            this._pepQuestionnaireUrlROM,
        )
        return {
            basicQuestionnaire: new Questionnaire(data.basicQuestionnaire),
            additionalQuestionnaire: data.additionalQuestionnaire
                ? new Questionnaire(data.additionalQuestionnaire)
                : undefined,
        }
    }

    async validateVendorProduct(payload: VendorProductValidationRequest) {
        const { data } = await this._httpClient.post<ValidateVendorResponse>(
            this._validateVendorProductUrl,
            payload,
        )
        return data
    }
    async getPepStatus(token: string) {
        const url = `${this._pepStatusUrl}/${token}`
        const { data } = await this._httpClient.get<'YES' | 'NO'>(url)
        return data
    }
    async getPepQuestionnaire() {
        const { data } = await this._httpClient.get<QuestionnaireDTO>(this._pepQuestionnaireUrl)
        return new Questionnaire(data)
    }

    async signOrBuy(payload: ContractSignOrBuyRequest, twoFaPin?: string) {
        const { data } = await this._httpClient.post<SignOrBuyResponse>(
            this._signOrBuyUrl,
            payload,
            {
                headers: {
                    'X-LeanPayCoreApp-TwoFAPin': twoFaPin ? twoFaPin : '',
                },
            },
        )

        return data
    }
    async financialProductInit(payload: FinancialProductV2Request) {
        const { data } = await this._httpClient.post<FinancialProductV2Request>(
            this._financialProductInitUrl,
            payload,
        )
        return data
    }

    async invalidateVendorRequest(request: VendorRequestInvalidateRequest) {
        const { data } = await this._httpClient.post(this._invalidateVendorRequestUrl, request)

        return data
    }

    async validateVendorRequest(request: VendorRequestInvalidateRequest) {
        const { data } = await this._httpClient.post(this._validateVendorRequestUrl, request)

        return data
    }

    async getPaymentData(request: PaymentDataRequest) {
        const { data } = await this._httpClient.post<PaymentDataDTO>(this._paymentDataUrl, request)

        return new PaymentData(data)
    }

    async getVendorTokenCheckout(initToken: string) {
        const { data } = await this._httpClient.post<VendorApiInitResponse>(
            `${this._getVendorTokenUrlCheckout}?token=${initToken}`,
            {},
        )
        return data
    }

    async requestFinancialData(request: RequestFinancialDataRequest) {
        const { data } = await this._httpClient.post<any>(this._requestFinancialDataUrl, request)

        return data
    }

    async triggerWatchListCheck(token: string) {
        const { data } = await this._httpClient.post(this._amlWatchListUrl, { token })

        return data
    }

    async getStateMachineStatus(token: string) {
        const { data } = await this._httpClient.get<StateMachineDto>(
            `${this._stateMachineUrl}?entityId=${token}`,
        )
        return data
    }

    async validateCheckoutOfferV2(request: ValidationDataV2DTO) {
        const { data } = await this._httpClient.post<ValidationDataDTO>(
            this._validateOfferUrlV2,
            request,
        )
        const { applicationId } = data
        await this.initScoringCheck({ applicationId, token: request.token })
        try {
            const scoringStatus = await this.getApplicationScoringStatus(
                applicationId,
                request.token,
            )
            if (scoringStatus?.status === ScoringStatus.FAILED) {
                throw new Error('SCORING_FAILED')
            }

            const validateOfferData = await this.getValidateOfferResult({
                applicationId,
                token: request.token,
            })

            return validateOfferData
        } catch (error: any) {
            if (error instanceof Error) {
                throw error
            } else if (typeof error === 'string') {
                throw new Error(error)
            } else {
                throw new Error(JSON.stringify(error))
            }
        }
    }
    async getContractData(request: TransactionRequest) {
        const { data } = await this._httpClient.post<ContractDataComplexDTO[]>(
            this._contractDataUrl,
            request,
        )

        return data.map((contract) => new ContractData(contract))
    }
    //PREQUALIFY
    async getVendorTokenPQ(initTokenData?: VendorApiPreQualifiedRequest) {
        const { data } = await this._httpClient.post<VendorApiInitResponse>(
            this._getVendorTokenPQUrlPQ,
            undefined,
            {
                params: {
                    vendor: initTokenData?.vendor,
                    returnUrl: initTokenData?.returnUrl,
                    productCode: initTokenData?.productCode,
                    requestType: initTokenData?.requestType,
                },
            },
        )

        return data
    }

    async getPreQualifiedInfo(request: PreQualifiedInfoRequest) {
        const { data } = await this._httpClient.post<PreQualifiedInfoDTO>(
            this._preQualifiedInfoUrl,
            request,
        )

        return new Vendor(data)
    }

    //COMMON

    async getValidateOfferResult(request: ValidationDataResultRequest) {
        const { data } = await this._httpClient.post<ValidationDataDTO>(
            this._validationResultUrl,
            request,
        )

        return new OfferValidation(data)
    }

    async getApplicationScoringStatus(applicationId: number, token: string) {
        const url = `${this._applicationScoringUrl}?applicationId=${applicationId}&token=${token}`
        const maxRetries = 36
        const retryInterval = 5000
        let timeoutId: number

        for (let i = 0; i < maxRetries; i++) {
            const { data } = await this._httpClient.get<ApplicationScoringResponse>(url)
            const status = data.status

            if (status === ScoringStatus.DONE) {
                clearTimeout(timeoutId!)
                return { status, applicationId }
            }

            if (status === ScoringStatus.FAILED) {
                clearTimeout(timeoutId!)
                return { status, applicationId }
            }

            // Wait for the specified interval before retrying
            await new Promise((resolve) => {
                timeoutId = setTimeout(resolve, retryInterval)
            })
        }

        throw Error('SCORING_FAILED')
    }

    async loadPersonalDetailsData(token: string) {
        const { data } = await this._httpClient.post<PersonalDetailsDTO>(
            this._loadPersonalDetailsUrl,
            {
                token: token,
            },
        )

        return new PersonalDetailsV2(data)
    }
    async loadAddressDetails(token: string) {
        const url = `${this._loadAddressDetails}?token=${token}`
        const { data } = await this._httpClient.get<AddressDetailsResponse>(url)

        return new AddressData(data)
    }
    async loadFinancialDetails(token: string) {
        const url = `${this._loadFinancialDetails}?token=${token}`
        const { data } = await this._httpClient.get<FinancialDetailsResponse>(url)

        return new FinancialDetailsV2(data as any)
    }

    async initScoringCheck(payload: ApplicationScoringRequest) {
        const { data } = await this._httpClient.post<ValidateOfferInitialResponse>(
            this._initScoringUrl,
            payload,
        )

        return data
    }

    async authenticate(payload: JWTLoginRequest): Promise<AuthResponse> {
        const res = await this._httpClient.post<AuthResponse>(this._authenticateUrl, payload)

        return res.data
    }

    async requestPIN(payload: AccountSingleStepPinRequest) {
        const { data } = await this._httpClient.post(this._requestPinUrl, payload)

        return data
    }

    async resendOtp(phoneNumber: string) {
        const request: AccountSingleStepPinRequest = {
            phoneNumber: phoneNumber,
        }

        const { data } = await this._httpClient.post(this._resendOtpUrl, request)
        return data
    }

    async resendOtpOverVoice(phoneNumber: string) {
        const request: AccountSingleStepPinRequest = {
            phoneNumber: phoneNumber,
        }

        const { data } = await this._httpClient.post(this._resendOtpOverVoiceUrl, request)

        return data
    }

    async checkPersonalDetails(payload: PersonalDetailsCheckRequest) {
        const { data } = await this._httpClient.post(this._checkPersonalDetailsUrl, payload)
        return data
    }

    async submitPersonalDetails(payload: PersonalDetailsV2Request) {
        const { data } = await this._httpClient.post(this._submitPersonalDetailsV2URL, payload)
        return data
    }

    async recordStep(payload: RecordStepParams) {
        // Prepare data string from data.
        let dataString: string = payload.data ? JSON.stringify(payload.data) : payload.data

        // Check if data string is to long.
        if (dataString && dataString.length > 40960) {
            dataString = dataString.substring(0, 40960)
        }

        const request: CheckoutStepRecordRequest = {
            token: payload.token,
            checkoutStepPriority: Date.now(),
            checkoutStep: payload.checkoutStep,
            checkoutStepViewType: payload.checkoutStepViewType,
            data: dataString,
        }

        const { data: res } = await this._httpClient.post(this._recordStepUrl, request)

        return res
    }

    async getIndustryCodebook() {
        const { data } = await this._httpClient.get<any[]>(this._industryCodebookUrl)
        return data.map((i) => new IndustryOfEmployment(i))
    }
    async getCountryCodebook() {
        const { data } = await this._httpClient.get<CountryCodebookDTO[]>(this._countryCodebookUrl)
        return data.map((c) => new Country(c))
    }
    async getNationalityCodebook() {
        const { data } = await this._httpClient.get<NationalityCodebookDTO[]>(
            this._nationalityCodebookUrl,
        )
        return data.map((c) => new Nationality(c))
    }
    async getSettlements(countryCodebookId: string) {
        const url = `${this._settlementsUrl}?countryCodebookId=${countryCodebookId}`
        const { data } = await this._httpClient.get<SettlementCodebookDTO[]>(url)
        return data.map((s) => new Settlement(s))
    }

    async getSettlementsByStreet(streetId: string) {
        const url = this._settlementsByStreetUrl + streetId
        const { data } = await this._httpClient.get<Page<StreetCodebookDTO>>(url)

        return data.content.map((s) => new Settlement(s))
    }

    async getSettlementsByPostZip(postZipCodebookId: string) {
        const url = `${this._settlementsByPostZipUrl}/${postZipCodebookId}`
        const { data } = await this._httpClient.get<SettlementCodebookDTO[]>(url)

        return data.map((s) => new Settlement(s))
    }

    async getPostZips(countryCodebookId: string) {
        const url = `${this._postZipsUrl}?countryCodebookId=${countryCodebookId}`
        const { data } = await this._httpClient.get<PostZipCodebookDTO[]>(url)

        return data.map((p) => new PostZip(p))
    }
    async getPostZipsBySettlement(settlementCodebookId: string) {
        const url = `${this._postZipBySettlementUrl}/${settlementCodebookId}`
        const { data } = await this._httpClient.get<PostZipCodebookDTO[]>(url)

        return data.map((p) => new PostZip(p))
    }

    async getStreetsByPostZip(postZipCodebookId: string) {
        const url = `${this._streetsByPostZipUrl + postZipCodebookId}?size=10000`
        const { data } = await this._httpClient.get<Page<StreetCodebookDTO>>(url)

        return data.content.map((s) => new Street(s))
    }

    async getStreetsBySettlement(settlementCodebookId: string) {
        const url = `${this._streetsBySettlementCodebookId}?settlementCodebookId=${settlementCodebookId}`
        const { data } = await this._httpClient.get<StreetCodebookDTO[]>(url)

        return data.map((s) => new Street(s))
    }

    async getFinancialDetailsOptions(type?: FinancialDetailsOptionsTypes): Promise<string[]> {
        const url = `${this._financialTypes}/${type}`
        const { data } = await this._httpClient.get<any>(url)

        return data?.financialMetaValue
    }

    async getEmploymentTypes() {
        const { data } = await this._httpClient.get<EmploymentMetaTypeDTO[]>(
            this._employmentTypesUrl,
        )

        return data.map((type) => new UserEmploymentType(type))
    }
    async getMaritalStatuses() {
        const { data } = await this._httpClient.get<MaritalStatus[]>(this._maritalStatusesUrl)

        return data
    }

    async getCompaniesList(page: number, size: number, search: string) {
        const url = `${this._companyListUrl}?page=${page}&size=${size}${search ? `&search=${search}` : ''}`
        const res = await this._httpClient.get<Page<CompaniesListDTO>>(url)

        return {
            companies: res.data.content,
            totalPages: res.data.totalPages,
            totalElements: res.data.totalElements,
        }
    }

    async loadMaritalStatus() {
        const { data } = await this._httpClient.get<MaritalStatusResponse>(this._maritalStatusUrl)

        return data
    }

    async submitMaritalStatus(payload: MaritalStatusRequest) {
        const { data } = await this._httpClient.post(this._maritalStatusUrl, payload)

        return data
    }
    async submitEmploymentInfo(payload: EmploymentInfoRequest) {
        const { data } = await this._httpClient.post(this._submitEmploymentInfo, payload)

        return data
    }

    async checkFinancialDetails(payload: FinancialDetailsCheckRequest) {
        const { data } = await this._httpClient.post<FinancialDetailsValidationDataDTO>(
            this._checkFinancialDetailsUrl,
            payload,
        )

        return data
    }

    async createPin(request: CreatePinRequest) {
        const { data } = await this._httpClient.post(this._createPinUrl, request)

        return data
    }

    async recreatePin(request: ReCreatePinRequest) {
        const { data } = await this._httpClient.post(this._recreatePinUrl, request)

        return data
    }
    async changePin(request: ChangePinRequest) {
        const { data } = await this._httpClient.post(this._changePinUrl, request)

        return data
    }

    async getCurrencyFromServer() {
        const { data } = await this._httpClient.get<CurrencyInfo>(this._currencyUrl)

        return new Currency(data)
    }

    async getSessionInfo() {
        const { data } = await this._httpClient.get<SessionInfoDTO>(this._sessionCheckUrl)
        storage.save(STORAGE_KEY.SESSION_INFO, data)

        return new SessionInfo(data)
    }

    async ibanUpdate(request: ProfileIbanChangeRequest, twoFaPin?: string) {
        await this._httpClient.post(this._ibanUpdateUrl, request, {
            headers: {
                'X-LeanPayCoreApp-TwoFAPin': twoFaPin ? twoFaPin : '',
            },
        })
    }

    async addressUpdate(request: ProfileAddressChangeRequest) {
        await this._httpClient.post(this._addressUpdateUrl, request)
    }

    async changeMarketingConsent(request: PersonMarketingConsentChangeRequest) {
        await this._httpClient.patch(this._changeMarketingConsentUrl, request)
    }

    async changeMarketingConsentLoginAction(request: PersonMarketingConsentChangeRequest) {
        await this._httpClient.patch(this._changeMarketingConsentLoginActionUrl, request)
    }

    async getLoanAmountLimits(request: LoanAmountLimitsRequest) {
        const { data } = await this._httpClient.get<any>(
            `${this._loanAmountLimitsUrl}?vendorId=${request.vendorId}`,
        )

        return data
    }

    async submitAddressDetailsV2(payload: AddressDetailsRequest) {
        const { data } = await this._httpClient.post<AddressDetailsRequest>(
            this._submitAddressDetailsV2,
            payload,
        )
        return data
    }

    async checkBankAccountNumber(payload: FinancialAccountNumberV2Request) {
        const { data } = await this._httpClient.post(this._checkBankAccountNumber, payload)

        return data
    }
    async submitFinancialDetails(payload: FinancialDetailsRequestV2) {
        const { data } = await this._httpClient.post<FinancialDetailsRequestV2>(
            this._submitFinancialDetails,
            payload,
        )

        return data
    }

    async getExecutionAmounts() {
        const { data } = await this._httpClient.get<ExecutionAmountMetaDTO[]>(
            this._executionAmountsUrl,
        )

        const executionAmounts = data
            .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
            .map((executionAmount) => new ExecutionAmount(executionAmount))
        return executionAmounts
    }

    async getFinancialData(token: string): Promise<FinancialDataResponse> {
        const url = `${this._requestFinancialDataUrl}/${token}`
        return new Promise((resolve, reject) => {
            const timeoutHandle = setTimeout(() => {
                return (async () => {
                    try {
                        const { data } = await this._httpClient.get<FinancialDataResponse>(url)

                        if (data.status === FinancialDataStatus.IN_PROCESSING) {
                            clearTimeout(timeoutHandle)
                            resolve(this.getFinancialData(token))
                        }

                        if (data.status === FinancialDataStatus.RESULTS_AVAILABLE) {
                            clearTimeout(timeoutHandle)
                            resolve(data)
                        }

                        if (data.status === FinancialDataStatus.RESULTS_INVALID) {
                            clearTimeout(timeoutHandle)
                            throw new Error(FinancialDataStatus.RESULTS_INVALID)
                        }

                        if (data.status === FinancialDataStatus.RESULTS_UNAVAILABLE) {
                            clearTimeout(timeoutHandle)
                            throw new Error(FinancialDataStatus.RESULTS_UNAVAILABLE)
                        }
                    } catch (error) {
                        clearTimeout(timeoutHandle)
                        reject(error)
                    }
                })()
            }, 5000)
        })
    }

    async cancelTransaction(payload: CancelTransactionRequest) {
        const { data } = await this._httpClient.post<any>(this._cancelTransactionUrl, payload)
        return data
    }
}

export const checkoutService = new CheckoutService()
