import { Environment, getCountryCodeBookId, getDefaultAppLanguage, Market } from '@leanpay/utils'

import Iphone11Img from 'assets/images/iphone_11.png'
import Iphone14Img from 'assets/images/iphone_14.png'
import Iphone8Img from 'assets/images/iphone_8.png'

// START COMMON CONSTANTS
const getMarketFromHostname = (): Market => {
    const hostname = window.location.hostname

    if (hostname.includes('.leanpay.ro') || hostname.includes('rou')) {
        return Market.ROM
    }

    if (hostname.includes('.leanpay.si') || hostname.includes('slo')) {
        return Market.SLO
    }

    if (hostname.includes('.leanpay.hu') || hostname.includes('hun')) {
        return Market.HUN
    }

    throw new Error(`[MARKET] Unknown hostname: ${hostname}`)
}
const isRunningOnLocalhost =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1') ||
    (window.location.hostname.includes('leanpay') && !window.location.hostname.includes('.'))

export const currentMarket = isRunningOnLocalhost
    ? (import.meta.env.VITE_MARKET as Market)
    : getMarketFromHostname()

export const isMarketSlo = currentMarket === Market.SLO
export const isMarketRom = currentMarket === Market.ROM
export const isMarketHun = currentMarket === Market.HUN

const getGtmId = () => {
    if (isMarketSlo) {
        return 'GTM-WKKBF5KC'
    }
    if (isMarketRom) {
        return 'GTM-NPG78W56'
    }
    if (isMarketHun) {
        return 'GTM-TK88QW62'
    }

    return ''
}

export const gtmId = getGtmId()

const getEnvironmentFromHostname = () => {
    const hostname = window.location.hostname

    if (isRunningOnLocalhost) {
        return Environment.LOCAL
    }

    if (hostname.includes('dev')) {
        return Environment.TILT
    }

    if (hostname.includes('test-checkout')) {
        return Environment.TEST
    }
    if (hostname.includes('stage-checkout')) {
        return Environment.STAGE
    }

    if (hostname.includes('checkout')) {
        return Environment.PROD
    }

    throw new Error(`[ENV] Unknown hostname: ${hostname}`)
}

export const currentEnvironment = getEnvironmentFromHostname()

export const isLocalEnvironment = currentEnvironment === Environment.LOCAL
export const isTestEnvironment = currentEnvironment === Environment.TEST
export const isStageEnvironment = currentEnvironment === Environment.STAGE
export const isProdEnvironment = currentEnvironment === Environment.PROD
export const isNonProdLiveEnvironment = !isProdEnvironment && !isLocalEnvironment

export const defaultAppLanguage = getDefaultAppLanguage(currentMarket)
export const countryCodebookId = getCountryCodeBookId(currentMarket)

// END COMMON CONSTANTS

export enum CacheKey {
    KYCStatus = 'KYCStatus',
    StateMachineStatus = 'StateMachineStatus',
    Time = 'Time',
    Country = 'Country',
    ExecutionAmounts = 'ExecutionAmounts',
    IndustryOfEmployment = 'IndustryOfEmployment',
    Nationality = 'Nationality',
    FinancialData = 'FinancialData',
    Settlement = 'Settlement',
    PostZip = 'PostZip',
    Streets = 'Streets',
    PaymentData = 'PaymentData',
    CurrencyInfo = 'CurrencyInfo',
    DownloadInitialOffer = 'DownloadInitialOffer',
    SystemConfig = 'SystemConfig',
    NumberOfConsecutivePaymentMonths = 'NumberOfConsecutivePaymentMonths',
    EmploymentType = 'EmploymentType',
    NumberOfRecentLoans = 'NumberOfRecentLoans',
    PEPQuestionnaire = 'PEPQuestionnaire',
    PepStatus = 'PepStatus',
    SessionInfo = 'SessionInfo',
    CreditCards = 'CreditCards',
    CreditCardStatus = 'CreditCardStatus',
    GlobalDocument = 'GlobalDocument',
    FinancialDetails = 'FinancialDetails',
    AddressDetails = 'AddressDetails',
    MaritalStatus = 'MaritalStatus',
    MaritalStatuses = 'MaritalStatuses',
    KYCCheck = 'KYCCheck',
}

export enum CheckoutRoute {
    ChoseInstallmentPlan = 'choose-installment',
    RequestOtp = 'request-otp',
    Login = 'login',
    PersonalDetails = 'personal-details',
    FinancialDetails = 'financial-details',
    PreviewFinancialDetails = 'preview-financial-details',
    AddressDetails = 'address-details',
    TwoFaPin = 'two-fa-pin',
    ForgotTwoFaPin = 'forgot-two-fa-pin',
    OfferConfirmation = 'offer-confirmation',
    SigContract = 'sign-contract',
    CheckoutError = 'checkout-error',
    CheckoutSuccess = 'checkout-success',
    VerificationInfo = 'verification-info',
    KYCVerification = 'kyc-verification',
    KYCVerificationSuccess = 'kyc-verification-success',
    ValidateOffer = 'validate-offer',
    Payment = 'payment',
    PaymentStatus = 'payment-status',
    PepQuestionnaire = 'pep-questionnaire',
    AisRequest = 'ais-request',
    AisStatus = 'ais-status',
}

export const cartItems = [
    {
        name: 'Vendor product 01',
        sku: '1001',
        price: 10.0,
        qty: 1,
        lpProdCode: 'product1',
    },
    {
        name: 'Vendor product 02',
        sku: '1002',
        price: 20.0,
        qty: 2,
        lpProdCode: 'product1',
    },
    {
        name: 'Vendor product 03',
        sku: '1003',
        price: 30.0,
        qty: 3,
        lpProdCode: 'product1',
    },
]

export const mockProductList = [
    {
        description: `   EMundia je trgovina za dom, specializirana za sisteme za izboljšanje kvalitete
        bivanja, kot so: sistemi za ogrevanje, hlajenje, prezračevanje, čistilne naprave
        in zbiralniki vode. V naši ponudbi pa najdete tudi belo tehniko, izdelke za vrt,
        orodje in mnogo drugih izdelkov za izboljšanje vsakdanjega življenja.`,
        name: 'Apple iPhone 14 - EMundia d.o.o.',
        productImage: Iphone14Img,
        vendorApiKey: '5182483f-9e19-407c-9cfd-2b62ae0d3583',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock#success',
        errorUrl: '/vendor-mock#error',
        vendorFirstName: 'Janez',
        vendorLastName: 'Novak',
        vendorEmail: 'janez.novak@leanpay.com',
        language: 'en',
        // vendorProductCode: 'emundiaVendorProductCode',
        amount: 900,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 11',
        productImage: Iphone11Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock#success',
        errorUrl: '/vendor-mock#error',
        vendorFirstName: 'Janez',
        vendorLastName: 'Novak',
        vendorEmail: 'janez.novak@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 150,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 8',
        productImage: Iphone8Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock#success',
        errorUrl: '/vendor-mock#error',
        vendorFirstName: 'Janez',
        vendorLastName: 'Novak',
        vendorEmail: 'janez.novak@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 150,
        cartItems: cartItems,
    },
]
export const mockProductListHUN = [
    {
        description: `EMundia je trgovina za dom, specializirana za sisteme za izboljšanje kvalitete
        bivanja, kot so: sistemi za ogrevanje, hlajenje, prezračevanje, čistilne naprave
        in zbiralniki vode. V naši ponudbi pa najdete tudi belo tehniko, izdelke za vrt,
        orodje in mnogo drugih izdelkov za izboljšanje vsakdanjega življenja.`,
        name: 'Apple iPhone 14 - EMundia d.o.o.',
        productImage: Iphone14Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Laszlo',
        vendorLastName: 'Varga',
        vendorEmail: 'laszlo.varga@leanpay.com',
        language: 'en',
        // vendorProductCode: 'emundiaVendorProductCode',
        amount: 30000,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 11',
        productImage: Iphone11Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Laszlo',
        vendorLastName: 'Varga',
        vendorEmail: 'laszlo.varga@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 270000,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 8',
        productImage: Iphone8Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Laszlo',
        vendorLastName: 'Varga',
        vendorEmail: 'laszlo.varga@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 28000,
        cartItems: cartItems,
    },
]
export const mockProductListROM = [
    {
        description: `EMundia je trgovina za dom, specializirana za sisteme za izboljšanje kvalitete
        bivanja, kot so: sistemi za ogrevanje, hlajenje, prezračevanje, čistilne naprave
        in zbiralniki vode. V naši ponudbi pa najdete tudi belo tehniko, izdelke za vrt,
        orodje in mnogo drugih izdelkov za izboljšanje vsakdanjega življenja.`,
        name: 'Apple iPhone 14 - EMundia d.o.o.',
        productImage: Iphone14Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Adrian',
        vendorLastName: 'Mutu',
        vendorEmail: 'adrian.mutu@leanpay.com',
        language: 'en',
        // vendorProductCode: 'emundiaVendorProductCode',
        amount: 900,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 11',
        productImage: Iphone11Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Adrian',
        vendorLastName: 'Mutu',
        vendorEmail: 'adrian.mutu@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 150,
        cartItems: cartItems,
    },
    {
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'Apple iPhone 8',
        productImage: Iphone8Img,
        vendorApiKey: '4',
        vendorTransactionId: `test-ignore-${new Date().getTime()}`,
        successUrl: '/vendor-mock',
        errorUrl: '/vendor-mock',
        vendorFirstName: 'Adrian',
        vendorLastName: 'Mutu',
        vendorEmail: 'adrian.mutu@leanpay.com',
        language: 'en',
        //  vendorProductCode: '',
        amount: 250,
        cartItems: cartItems,
    },
]

export const getDashboardUrl = (): string => {
    switch (currentMarket) {
        case Market.HUN:
            if (isProdEnvironment) {
                return 'https://app.leanpay.hu/dashboard'
            }

            if (isTestEnvironment) {
                return 'https://test-app.leanpay.hu/dashboard'
            }

            if (isStageEnvironment) {
                return 'https://stage-app.leanpay.hu/dashboard'
            }
            return 'http://localhost:4200/dashboard'
        case Market.ROM:
            if (isProdEnvironment) {
                return 'https://app.leanpay.ro/dashboard'
            }

            if (isTestEnvironment) {
                return 'https://test-app.leanpay.ro/dashboard'
            }

            if (isStageEnvironment) {
                return 'https://stage-app.leanpay.ro/dashboard'
            }

            return 'http://localhost:4200/dashboard'
        case Market.SLO:
            if (isProdEnvironment) {
                return 'https://app.leanpay.si/dashboard'
            }

            if (isTestEnvironment) {
                return 'https://test-app.leanpay.si/dashboard'
            }

            if (isStageEnvironment) {
                return 'https://stage-app.leanpay.si/dashboard'
            }
            return 'http://localhost:4200/dashboard'
        default:
            return 'http://localhost:4200/dashboard'
    }
}

export const getPQUrl = (): string => {
    switch (currentMarket) {
        case Market.SLO:
            if (isProdEnvironment) {
                return 'https://pre-qualify.leanpay.si/?vendor=3ER7JP'
            }
            if (isTestEnvironment) {
                return 'https://test-pre-qualify.leanpay.si/?vendor=3ER7JP&returnUrl=http://test-checkout.leanpay.si/vendor-mock'
            }
            if (isStageEnvironment) {
                return 'https://stage-pre-qualify.leanpay.si/?vendor=3ER7JP&returnUrl=http://stage-checkout.leanpay.si/vendor-mock'
            }
            return 'http://localhost:4303/?vendor=3ER7JP&returnUrl=http://localhost:4301/vendor-mock'

        case Market.ROM:
            if (isProdEnvironment) {
                return 'https://pre-qualify.leanpay.ro/?vendor=3ER7JP'
            }
            if (isTestEnvironment) {
                return 'https://test-pre-qualify.leanpay.ro/?vendor=3ER7JP&returnUrl=http://test-checkout.leanpay.ro/vendor-mock'
            }
            if (isStageEnvironment) {
                return 'https://stage-pre-qualify.leanpay.ro/?vendor=3ER7JP&returnUrl=http://stage-checkout.leanpay.ro/vendor-mock'
            }

            return 'http://localhost:4303/?vendor=3ER7JP&returnUrl=http://localhost:4301/vendor-mock'

        default:
            return 'http://localhost:4303/?vendor=3ER7JP&returnUrl=http://localhost:4301/vendor-mock'
    }
}

//Steps for validating offer for ROM market
export enum ValidateOfferStepsROM {
    IDENTITY_VERIFICATION = 'identityVerification',
    FINANCIAL_DATA_CONFIRMATION = 'financialDataConfirmation',
    OFFER_VALIDATION = 'offerValidation',
    SCORING_ANALYSIS = 'scoringAnalysis',
}

export const shouldSkipKYC =
    import.meta.env.VITE_MARKET === Market.ROM && import.meta.env.VITE_SKIP_KYC === 'true'
