import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom'

import App from 'app/app'

import { currentEnvironment, currentMarket, gtmId, isProdEnvironment } from 'library/constants'

import './i18n'

const sentryClient = Sentry.init({
    dsn: 'https://56d872c3bbdc78970e207b4727b720b2@o4508023659560960.ingest.de.sentry.io/4508023664017488',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    enabled: true,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: currentEnvironment.toLowerCase(),
    ignoreErrors: [
        'Non-Error promise rejection captured with value: VENDOR_API_TOKEN_UNKNOWN',
        'Non-Error promise rejection captured with value: 502',
        'Failed to fetch dynamically imported module',
        'Network Error',
        "Can't find variable: gmo",
        'Fetch request to',
        'The object can not be cloned',
    ],
})
Sentry.setTag('market', currentMarket.toLowerCase())

if (isProdEnvironment) {
    const tagManagerArgs = {
        gtmId,
    }

    TagManager.initialize(tagManagerArgs)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
