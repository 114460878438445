import { styled, theme } from '@leanpay/stitches'
import React, { ReactNode } from 'react'

import { LoadingSpinner } from '../loading-spinner/loading-spinner'

type StyledButtonProps = React.ComponentProps<typeof StyledButton>
interface ButtonProps extends StyledButtonProps {
    children?: ReactNode
    loading?: boolean
    disabled?: boolean
    testId?: string
}

export const Button = ({ testId = '', ...props }: ButtonProps) => {
    return (
        <StyledButton
            data-testid={testId ? testId : props.type === 'submit' ? 'button-submit' : 'button'}
            disabled={props.disabled || props.loading}
            type={props.type}
            css={{ ...props.css }}
            variant={props.variant}
            size={props.size}
            onClick={props.onClick}
        >
            {props.loading ? (
                <LoadingSpinner
                    variant={props.variant === 'secondary' ? 'primary' : 'secondary'}
                    size={'l'}
                />
            ) : (
                props.children
            )}
        </StyledButton>
    )
}

const StyledButton = styled('button', {
    'appearance': 'none',
    'fontFamily': '$sourceSansPro',
    'display': 'flex',
    'width': '100%',
    'flexDirection': 'columns',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': 'fit-content',
    'borderRadius': '$0-75',
    'cursor': 'pointer',
    'border': 'none',
    'box-shadow': '$buttonShadow',
    'fontWeight': '$semiBold',
    'letterSpacing': 'normal',
    'fontSize': '$3',
    'lineHeight': '20px',
    'textTransform': 'uppercase',
    'padding': 14,
    'backgroundRepeat': 'noRepeat',
    'color': '$white',
    'variants': {
        variant: {
            primary: {
                'boxShadow': '0px 4px 8px rgba(0, 0, 0, 0.16)',
                'background': '$peach1',
                'transition': theme.transition.btnTransition,
                '&:hover': {
                    background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FF6A71',
                    transform: theme.transform.transformY,
                },
                '&:disabled': {
                    'background': 'rgba(255, 106, 113, 0.4)',
                    'transition': 'none',
                    'transform': 'none',
                    '&:hover': {
                        transform: 'none',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                    },
                },

                'a': {
                    color: '$white',
                },
            },
            secondary: {
                'color': '$dark1',
                'background': '$white',
                'border': '2px solid $grey1',
                'boxShadow': '0px 4px 8px 0px rgba(38, 50, 56, 0.16)',
                '&:hover': {
                    transform: theme.transform.transformY,
                    background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF',
                },
                'a': {
                    color: '$white',
                },
                '&:disabled': {
                    background: '$grey1',
                },
            },
            outline: {
                'padding': 0,
                'boxShadow': '0px 4px 8px rgba(0, 0, 0, 0.16)', // Updated shadow
                'color': '$dark1',
                'border': '1px solid $line',
                'backgroundColor': 'transparent',
                '&:hover': {
                    'background':
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #FFFFFF',
                    //handle the case when the button is disabled and hovered
                    '&:disabled': {
                        background: 'transparent',
                    },
                },
                '&:disabled': {
                    opacity: 0.5,
                },
            },
            text: {
                'boxShadow': 'none',
                'padding': 0,
                'color': '$dark1',
                'backgroundColor': 'transparent',
                '&:hover': {
                    transform: theme.transform.transformY,
                },
                '&:disabled': {
                    opacity: 0.5,
                },
            },
        },
        size: {
            s: {
                height: 20,
            },
            m: {
                height: 32,
            },
            l: {
                height: 48,
            },
        },
    },
    'defaultVariants': {
        size: 'l',
        variant: 'primary',
    },
})
