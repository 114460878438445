import { memo } from 'react'

const ToolTipIcon = () => (
    <svg width="17" height="17" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.28631 0.5C3.32545 0.5 0.099976 3.71667 0.099976 7.66667C0.099976 11.6167 3.32545 14.8333 7.28631 14.8333C11.2472 14.8333 14.4726 11.6167 14.4726 7.66667C14.4726 3.71667 11.2472 0.5 7.28631 0.5ZM6.61767 4.33333V5.66667H7.95494V4.33333H6.61767ZM6.61767 7V11H7.95494V7H6.61767ZM1.93724 7.66667C1.93724 10.6067 4.33764 13 7.28631 13C10.235 13 12.6354 10.6067 12.6354 7.66667C12.6354 4.72667 10.235 2.33333 7.28631 2.33333C4.33764 2.33333 1.93724 4.72667 1.93724 7.66667Z"
            fill="#8F9DB0"
            stroke="#8F9DB0"
            strokeWidth="0.15"
        />
    </svg>
)

export default memo(ToolTipIcon)
