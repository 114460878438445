import * as Sentry from '@sentry/react'
import axios, {
    AxiosInstance,
    AxiosInterceptorOptions,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'

export interface HttpClientInterceptorConfig {
    onRequestFulfilled?: (
        value: AxiosRequestConfig,
    ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>
    onResponseFulfilled?: (value: AxiosResponse) => AxiosResponse
    onRejected?: (error: any) => any
    options?: AxiosInterceptorOptions
}

export interface HttpClientConstructor {
    config: any
    interceptorConfig?: HttpClientInterceptorConfig
}

export class HttpClient {
    http: AxiosInstance

    constructor({ config, interceptorConfig }: HttpClientConstructor) {
        this.http = axios.create(config)
        this.http.interceptors.request.use(
            interceptorConfig?.onRequestFulfilled as any,
            interceptorConfig?.onRejected,
            interceptorConfig?.options,
        )
        this.http.interceptors.response.use(
            interceptorConfig?.onResponseFulfilled,
            interceptorConfig?.onRejected,
        )
    }

    private async wrapWithSentry<T>(
        operation: string,
        name: string,
        fn: () => Promise<AxiosResponse<T>>,
    ): Promise<AxiosResponse<T>> {
        return Sentry.startNewTrace(async () => {
            return await Sentry.startSpan({ op: operation, name }, fn)
        })
    }

    async get<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.wrapWithSentry('http.get', `GET ${path}`, () => this.http.get<T>(path, config))
    }

    async post<T>(
        path: string,
        payload: unknown,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.wrapWithSentry('http.post', `POST ${path}`, () =>
            this.http.post<T>(path, payload, config),
        )
    }

    async put<T>(
        path: string,
        payload: unknown,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.wrapWithSentry('http.put', `PUT ${path}`, () =>
            this.http.put<T>(path, payload, config),
        )
    }

    async patch<T>(
        path: string,
        payload: unknown,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return this.wrapWithSentry('http.patch', `PATCH ${path}`, () =>
            this.http.patch<T>(path, payload, config),
        )
    }

    async delete<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.wrapWithSentry('http.delete', `DELETE ${path}`, () =>
            this.http.delete<T>(path, config),
        )
    }
}
